import classes from './EmployeeAccountCard.module.scss';
import { Dispatch, FC, MouseEvent, SetStateAction, memo, useState } from 'react';
import credit_card from 'shared/assets/icons/credit_card.svg';
import editSvg from 'shared/assets/icons/editSvg.svg';
import deleteImg from 'shared/assets/icons/delete.svg';
import { Dropdown } from 'antd';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import type { MenuProps } from 'antd';


interface EmployeeAccountCardProps {
  selected: boolean,
  account_number: string | number,
  full_name: string,
  onEdit: (id: string) => void,
  onDelete: (id: string) => void,
  setSelectId: Dispatch<SetStateAction<number | undefined>>,
  idBack: string,
  id: number,
}


const EmployeeAccountCard: FC<EmployeeAccountCardProps> = (props) => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    id,
    idBack,
    setSelectId,
    selected,
    account_number,
    full_name,
    onEdit,
    onDelete,
  } = props;
  const [ open, setOpen ] = useState<boolean>(false);
  const handleOk = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    onDelete(idBack);
    setOpen(false);
  };
  const handleDisagree = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setOpen(false);
  };
  const items: MenuProps['items'] = [
    {
      type: 'group',
      label: <div className={classes.alert}>Удалить карту?</div>,
      key: '0',
    },
    { type: 'divider' },
    {
      label: <div onClick={handleOk}>Да</div>,
      key: '1',
    },
    {
      label: <div onClick={handleDisagree}>Нет</div>,
      key: '3',
    },
  ];
  return (
    <div
      className={`${classes.container} ${selected ? classes.selected : classes.default}`}
      onClick={isMobile ? () => setSelectId(id) : () => null}
    >
      {
        !isMobile
        &&
        <div className={classes.select}
          onClick={() => setSelectId(id)}
        ></div>
      }
      <div className={classes.content}>
        <div className={classes.logo}><img src={credit_card} alt="" /></div>
        <div className={classes.right}>
          <div className={classes.text}>
            <div>{account_number}</div>
            <div>{full_name}</div>
          </div>
          <Dropdown open={open} placement={'bottom'} menu={{ items }} trigger={[ 'click' ]}>
            <div onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }} className={classes.logoDelete}>
              <img src={deleteImg} alt="удалить" />
            </div>
          </Dropdown>
          <div className={classes.logoEdit}>
            <img onClick={() => onEdit(idBack)} src={editSvg} alt="редактировать" />
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default memo(EmployeeAccountCard);
