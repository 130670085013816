import classes from './EmployeeDream.module.scss';
import EmployeeProgressBar from '../EmployeeProgressBar/EmployeeProgressBar';
import EmployeeAchievements from '../EmployeeAchievements/EmployeeAchievements';
import EmployeeDreamFormInputs from '../EmployeeDreamFormInputs/EmployeeDreamFormInputs';
import MobileButtonRequest from '../EmployeeModal/components/MobileButtonRequest/MobileButtonRequest';
import { setSeparatorNum } from 'shared/common/constants';
import CustomButton from 'shared/UI/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { ChangeEvent, FC, FormEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  employeeActions,
  getDreams,
  patchDream,
  patchProfile,
} from 'reduxApi/slices/employeeSlice/model/employeeSlice';
import { IDreamFormState } from 'reduxApi/slices/employeeSlice/type/employeeSchema';
import { PhoneMaskHelper } from 'shared/common/helpers';


export const MAX_LENGTH_TEXT_AREA = 50;

const initialDreamFormState = {
  valueDream: '',
  valueGoal: '',
  mBankNumber: '',
};

const EmployeeDream: FC = () => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.employee.employee);
  const dreams = useAppSelector(state => state.employee.dreams);
  const balance = setSeparatorNum(user?.balance);

  const [ dreamState, setDreamState ] = useState<IDreamFormState>(initialDreamFormState);
  const [ isChanged, setIsChanged ] = useState<boolean>(false);
  const [ open, setOpen ] = useState<boolean>(false);


  const handleChangeInput = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    if (name === 'mBankNumber') {
      PhoneMaskHelper(value, name, setDreamState);
    } else if (value.length <= MAX_LENGTH_TEXT_AREA) {
      setDreamState(prevDreamState => ({ ...prevDreamState, [ name ]: value }));
    }
  };

  const isMakingChanges = (dreamState.valueDream !== user?.dream?.title) ||
  (dreamState.valueGoal !== user?.dream?.goal) ||
  (dreamState.mBankNumber !== user?.phone);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if ((user?.dream?.progress ?? 0) >= +dreamState.valueGoal) {
      toast.error('К сожалению, вы не можете указать сумму, меньшую или равную полученной.');
      return;
    }
    if (isMakingChanges) {
      dispatch(patchDream({
        id: user?.id,
        title: dreamState.valueDream,
        goal: dreamState.valueGoal,
      }))
        .then(({ payload }) => {
          if ((payload?.data?.title === dreamState.valueDream) || (payload?.data?.goal === dreamState.valueGoal)) {
            dispatch(employeeActions.updateDreams(payload.data));
            setIsChanged(false);
            toast.success('Данные сохранены');
          } else {
            toast.error('Произошла ошибка');
          }
        },
        );
    }
    if (user?.phone !== dreamState.mBankNumber) {
      dispatch(patchProfile({ phone: dreamState.mBankNumber }));
    }
  };

  useEffect(() => {
    if (!user?.dream) return;
    setDreamState({
      valueDream: user?.dream?.title ?? '',
      valueGoal: user?.dream?.goal ?? '',
      mBankNumber: user?.phone ?? '+996',
    });
    dispatch(getDreams());
  }, [ user?.dream ]);

  return (
    <form onSubmit={handleSubmit} className={classes.container}>
      <div>
        <CustomButton
          type="button"
          classname={classes.currentCheckButton}
          disabled
          color={'gradient_purple'}
          variant={'contained'}
        >
          <div className={classes.currentCheck}>
            Текущий счет:
          </div>
          <div className={classes.check}>
            {balance || 0} сом
          </div>
        </CustomButton>
        {
          !isDesktop &&
          <p>{user?.dream?.title}</p>
        }
        <EmployeeProgressBar dream={user?.dream}/>
        {
          isDesktop
          &&
          <EmployeeDreamFormInputs
            isChanged={isChanged}
            handleChangeInput={handleChangeInput}
            dreamState={dreamState}
            setIsChanged={setIsChanged}
            handleSubmit={handleSubmit}
            isMakingChanges={isMakingChanges}
          />
        }
      </div>
      <CustomButton
        type="button"
        color='white'
        variant='text'
        classname={classes.historyAchievements}
        onClick={() => setOpen(true)}
      >
        История достижений
      </CustomButton>
      {!isDesktop && <MobileButtonRequest/>}
      <EmployeeAchievements
        open={open}
        setOpen={setOpen}
        dreams={dreams}
      />
    </form>
  );
};
export default EmployeeDream;
