import classes from './EmployeeRevCard.module.scss';
import DraggableOverflow from '../../../../shared/UI/DraggableOverflow/DraggableOverflow';
import { EEmojiTypes } from 'shared/common/constants';
import GetRatingEmoji from 'shared/UI/GetRatingEmoji/GetRatingEmoji';
import { useAppSelector } from 'shared/hooks/redux';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { FC } from 'react';
import PhotosGallery from 'shared/UI/PhotosGallery/PhotosGallery';


interface EmployeeRevCardProps {
  comment: string,
  rating: EEmojiTypes,
  date: string,
  hour: string
  reviews: string[],
  review_images?: string[],
}

const EmployeeRevCard: FC<EmployeeRevCardProps> = (props) => {
  const { comment, rating, date, hour, reviews, review_images } = props;
  const user = useAppSelector(state => state.employee.employee);

  const isMobile = useMediaQuery('(max-width:768px)');

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.top}>
          <div className={classes.author}>
            <p className={classes.full_name}>{user?.full_name}</p>
            {isMobile && <GetRatingEmoji rating={rating}/>}
          </div>
          <div
            className={classes.reviewsNSmile}
          >
            <DraggableOverflow classname={classes.reviews}>
              {
                reviews?.map((review, index) => (
                  <p className={classes.review} key={index}>{review}</p>
                ))
              }
            </DraggableOverflow>
            {!isMobile && <GetRatingEmoji rating={rating}/>}
          </div>
        </div>
        <span className={classes.mid}>
          {comment}
        </span>
        <PhotosGallery images={review_images}/>
        <div className={classes.bottom}>
          {hour + ' ' + date}
        </div>
      </div>
    </div>
  );
};

export default EmployeeRevCard;
