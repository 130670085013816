import classes from './MobileButtonRequest.module.scss';
import EmployeeModal from '../../EmployeeModal';
import CustomButton from 'shared/UI/CustomButton/CustomButton';
import { FC, useState } from 'react';


const MobileButtonRequest: FC = () => {
  const [ open, setOpen ] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <div className={classes.container}>
      <CustomButton onClick={handleOpen} color={'gradient_purple'} variant={'contained'}>
        Запрос на вывод средств
      </CustomButton>
      <EmployeeModal open={open} setModal={setOpen}/>
    </div>
  );
};
export default MobileButtonRequest;
