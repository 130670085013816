import classes from './EmployeeCards.module.scss';
import { ETabValues } from '../EmployeeModal';
import { ChangeEvent, Dispatch, FC, SetStateAction, useState } from 'react';
import {
  IDataParamsBankAccount,
  IFormRequestMoney,
  TFormBankAccount,
} from 'reduxApi/slices/bankAccountSlice/type/bankAccountType';
import CustomInput from 'shared/UI/CustomInput/CustomInput';
import CustomButton from 'shared/UI/CustomButton/CustomButton';
import { useAppDispatch, useAppSelector } from 'shared/hooks/redux';
import {
  getListBankAccounts,
  patchPartialUpdateBankAccount,
  postCreateBankAccount,
} from 'reduxApi/slices/bankAccountSlice/model/bankAccountSlice';
import AnimationContainer from 'shared/UI/AnimationContainer/AnimationContainer';


export enum ETypesOfCard {
  MBANK = 'MBank',
  BANK_CARD = 'Банковский счет'
}

export enum ETypeCardFrame {
  CREATE = 'CREATE',
  CHANGE = 'CHANGE',
}

interface IPropsEmployeeCards {
  setTab: Dispatch<SetStateAction<ETabValues>>,
  type?: ETypeCardFrame,
  idCard?: string | null,
}


const EmployeeCards: FC<IPropsEmployeeCards> = (props) => {
  const { setTab, type = ETypeCardFrame.CREATE, idCard } = props;
  const dispatch = useAppDispatch();
  const bankAccount = useAppSelector(state => state.bankAccount.bankAccount);

  const findedCard = bankAccount.find(item => item.id === idCard);
  const [ form, setForm ] = useState<TFormBankAccount>({
    full_name: {
      placeholder: 'ФИО',
      value: type === ETypeCardFrame.CREATE ? '' : findedCard?.full_name || '',
      error: false,
      regExp: /./,
    },
    inn: {
      placeholder: 'INN',
      value: type === ETypeCardFrame.CREATE ? '' : findedCard?.inn || '',
      error: false,
      regExp: /./,
    },
    bank_code: {
      placeholder: 'BIK Банка',
      value: type === ETypeCardFrame.CREATE ? '' : findedCard?.bank_code || '',
      error: false,
      regExp: /./,
    },
    account_number: {
      placeholder: 'Номер счета',
      value: type === ETypeCardFrame.CREATE ? '' : findedCard?.account_number || '',
      error: false,
      regExp: /^.{16}$/,
    },
  });

  const handleChangeForm = (
    setForm: Dispatch<SetStateAction<TFormBankAccount>>,
    key: keyof IFormRequestMoney,
    value: string,
  ) => {
    setForm((prevForm: TFormBankAccount) => ({
      ...prevForm,
      [ key ]: {
        ...prevForm[ key ],
        value,
        error: !prevForm[ key ].regExp.test(value),
      },
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>, key: (keyof IFormRequestMoney)): void => {
    if (key === 'account_number') {
      if (/^[0-9\s]+$/.test(e.target.value) || e.target.value === '') {
        handleChangeForm(setForm, key, e.target.value);
      }
    }
    else {
      handleChangeForm(setForm, key, e.target.value);
    }
  };

  const handleSubmit = async () => {
    const backForm: IDataParamsBankAccount = {};
    Object.keys(form).forEach((item: keyof IFormRequestMoney) => {
      if (!form[ item ].regExp.test(form[ item ].value)) {
        handleChangeForm(setForm, item, form[ item ].value);
      } else if (item === 'amount') {
        backForm[ item ] = form[ item ].value.replace(/\s/g, '');
      } else {
        backForm[ item ] = form[ item ].value;
      }
    });
    if (Object.values(form).every(elem => elem.error === false && elem.value !== '')) {
      if (type === ETypeCardFrame.CREATE) {
        await dispatch(postCreateBankAccount({ ...backForm, type: ETypesOfCard.BANK_CARD }));
      } else {
        backForm.id = idCard || '';
        await dispatch(patchPartialUpdateBankAccount(backForm));
      }
      await dispatch(getListBankAccounts());
      setTab(ETabValues.EMPLOYEE_SELECT_CARD);
    }
  };

  return (
    <AnimationContainer>
      <div>
        <div className={classes.text}>
          {
            type === ETypeCardFrame.CREATE
              ?
              'Добавление счета'
              :
              'Редактирование счета'
          }

        </div>
        <div className={classes.content}>
          {
            Object.keys(form).map(item =>
              <CustomInput
                key={form[ item ].placeholder}
                placeholder={form[ item ].placeholder}
                color={'bordered_purple'}
                value={form[ item ].value}
                onChange={(e) => handleChange(e, item)}
                error={form[ item ].error}

              />)
          }
          <div className={classes.actionButtons}>
            <CustomButton
              classname={classes.cancel}
              color={'black'}
              variant={'outlined'}
              onClick={() => setTab(ETabValues.EMPLOYEE_SELECT_CARD)}
            >
            Отмена
            </CustomButton>
            <CustomButton
              color={'black'}
              variant={'contained'}
              onClick={handleSubmit}
            >
              {
                type === ETypeCardFrame.CREATE
                  ?
                  'Добавить'
                  :
                  'Готово'
              }
            </CustomButton>
          </div>
        </div>
      </div>
    </AnimationContainer>
  );
};
export default EmployeeCards;
