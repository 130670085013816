import classes from './EmployeeDreamFormInputs.module.scss';
import { PATH } from 'shared/common/constants';
import CustomButton from 'shared/UI/CustomButton/CustomButton';
import CustomInput from 'shared/UI/CustomInput/CustomInput';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { FC, ChangeEvent, Dispatch } from 'react';
import { IDreamFormState } from 'reduxApi/slices/employeeSlice/type/employeeSchema';


interface IPropsEmployeeDreamFormInputs {
  isChanged: boolean
  handleChangeInput: (event: ChangeEvent<HTMLInputElement>) => void
  dreamState: IDreamFormState
  setIsChanged: Dispatch<React.SetStateAction<any>>
  handleSubmit: Dispatch<React.SetStateAction<any>>
  isMakingChanges: boolean
}

export const MAX_LENGTH_TEXT_AREA = 50;

const EmployeeDreamFormInputs: FC<IPropsEmployeeDreamFormInputs> = (props) => {
  const isDesktop = useMediaQuery('(min-width: 768px)');
  const { isChanged, handleChangeInput, dreamState, setIsChanged, handleSubmit, isMakingChanges } = props;

  return (
    <>
      <CustomInput
        readOnly={!isChanged}
        placeholder={'Мечта'}
        color={'bordered_purple'}
        value={dreamState.valueDream}
        onChange={handleChangeInput}
        name='valueDream'
        afterSuffix={
          <div>
            {dreamState.valueDream.length} / {MAX_LENGTH_TEXT_AREA}
          </div>
        }
      />
      <CustomInput
        readOnly={!isChanged}
        placeholder={'Целевая сумма'}
        color={'bordered_purple'}
        value={dreamState.valueGoal}
        name='valueGoal'
        onChange={handleChangeInput}
      />
      <CustomInput
        readOnly={!isChanged}
        placeholder={'Номер телефона МБанк'}
        color={'bordered_purple'}
        value={dreamState.mBankNumber}
        name='mBankNumber'
        onChange={handleChangeInput}
      />
      {
        (!(window.location.pathname === PATH.editEmployeeProfile) && isChanged && isDesktop)
        &&
        <div className={classes.buttonsContainer}>
          <CustomButton
            type="submit"
            classname={classes.saveButton}
            color={'purple'}
            variant={'contained'}
            onClick={handleSubmit}
            disabled={!isMakingChanges}
          >
            Сохранить
          </CustomButton>
          <CustomButton
            color={'purple'}
            variant={'outlined'}
            onClick={() => setIsChanged(false)}
            classname={classes.saveButton}
          >
            Отмена
          </CustomButton>
        </div>
      }
      {
        (!isChanged && isDesktop) &&
        <CustomButton
          variant={'outlined'}
          color={'purple'}
          onClick={() => setIsChanged(true)}
          classname={classes.editButton}
        >
          Редактировать
        </CustomButton>
      }
    </>
  );
};

export default EmployeeDreamFormInputs;
