import classes from './EmployeeTipCard.module.scss';
import { TColorEmployeeCard, setSeparatorNum } from 'shared/common/constants';
import { FC } from 'react';
import { EStatuses } from 'reduxApi/slices/employeeSlice/type/employeeSchema';


interface EmployeeTipCardCardProps {
  date: string,
  amount: string | number | undefined,
  type?: TColorEmployeeCard
  text: string
}

const EmployeeTipCard: FC<EmployeeTipCardCardProps> = (props) => {
  const { date, amount, type, text } = props;

  const textChanger = () => {
    if (text === EStatuses.pending) {
      return 'В ожидании';
    } else if (text === EStatuses.canceled) {
      return 'Отменено';
    } else if (type === 'green') {
      return 'Зачислено';
    } else {
      return 'Выведено';
    }
  };

  return (
    <div className={`${classes.container} ${classes[ type || '' ]}`}>
      <div className={classes.date}>
        {textChanger()}: {date}
      </div>
      <div className={classes.amount}>{setSeparatorNum(amount ? amount : 0)} сом</div>
    </div>
  );
};

export default EmployeeTipCard;
