import classes from './EmployeeInfiniteScroll.module.scss';
import EmployeeTipCard from '../EmployeeTipCard/EmployeeTipCard';
import EmployeeRevCard from '../EmployeeRevCard/EmployeeRevCard';
import SkeletonButton from 'antd/es/skeleton/Button';
import { useAppDispatch } from 'shared/hooks/redux';
import { FC, memo, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ETypesCardEmployee, REQUEST_STATUSES, TColorEmployeeCard } from 'shared/common/constants';
import { ISchemaEmployee, ITipsReviewsRequestMoney } from 'reduxApi/slices/employeeSlice/type/employeeSchema';
import { employeeActions } from 'reduxApi/slices/employeeSlice/model/employeeSlice';
import useMediaQuery from 'shared/hooks/useMediaQuery';


interface EmployeeInfiniteScrollProps {
  getDispatchData: (arg: any) => any,
  dataSelector: ITipsReviewsRequestMoney,
  color?: TColorEmployeeCard,
  type?: ETypesCardEmployee,
  dataStatus: REQUEST_STATUSES,
  hardDep?: any,
  clearData?: keyof ISchemaEmployee,
  specParams?: {
    [key: string]: any
  },
}
enum ETypes {
  limit = 'limit',
  offset = 'offset'
}

const EmployeeInfiniteScroll: FC<EmployeeInfiniteScrollProps> = (props) => {
  const {
    hardDep,
    specParams,
    dataStatus,
    getDispatchData,
    dataSelector,
    color,
    clearData,
    type = ETypesCardEmployee.tips,
  } = props;
  const isMobile = useMediaQuery('(max-width: 768px)');
  const dispatch = useAppDispatch();
  const idContainer = `scrollableContainer${type}`;
  const [ hasMore, setHasMore ] = useState(true);

  useEffect(() => {
    setHasMore(true);
    if (clearData) {
      dispatch(employeeActions.clearData(clearData!));
      dispatch(getDispatchData({
        [ ETypes.limit ]: 21,
        [ ETypes.offset ]: 0,
        ...specParams,
      }));
    }
    //FIX_ME
    // if (
    //   dataStatus === REQUEST_STATUSES.NOT_REQUESTED
    //   ||
    //   dataStatus === REQUEST_STATUSES.FAILED
    //   ||
    //   hardDep
    // ) {
    // }
  }, [ getDispatchData, hardDep ]);
  const fetchData = () => {
    if (dataSelector?.next) {
      const url = new URL(dataSelector.next);
      const params = new URLSearchParams(url.search);

      if (params.get(ETypes.limit) && params.get(ETypes.offset)) {
        dispatch(getDispatchData({
          limit: params.get(ETypes.limit) as string,
          offset: params.get(ETypes.offset) as string,
          ...specParams,
        }));
      }
    } else if (
      (dataSelector.count === dataSelector.results?.length)
      &&
      (dataSelector.count && dataSelector.results?.length)
    ) {
      setHasMore(false);
    }
  };
  return (
    <div id={idContainer} className={classes.container}>
      <InfiniteScroll
        className={`${classes.infinityContainer} ${classes[ type ]}`}
        dataLength={dataSelector.results?.length || 0}
        next={fetchData}
        hasMore={hasMore}
        loader=
          {
            Array(6)
              .fill(null)
              .map((_, i) =>
                <SkeletonButton
                  key={i}
                  className={classes.skeleton}
                  size={'large'}
                  block
                />)
          }
        height={isMobile ? 600 : undefined}
        scrollableTarget={idContainer}
      >
        {
          dataSelector.results?.map((item, i) =>
            type === ETypesCardEmployee.tips
              ?
              <EmployeeTipCard
                key={i}
                date={`${item?.date}  ${item?.hour}`}
                amount={item?.amount}
                type={color}
                text={clearData!}
              />
              :
              <EmployeeRevCard
                review_images={item?.review_images}
                comment={item?.comment || ''}
                rating={item?.rating || 0}
                date={item.date || ''}
                hour={item.hour || ''}
                reviews={item.review_templates || []}
                key={i}
              />,
          )}
      </InfiniteScroll>

    </div>
  );
};

export default memo(EmployeeInfiniteScroll);
